<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="connectors-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("reports.reports.reports") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper connectors-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section" v-if="reports && reports.length">
      <li
        class="clebex-item-section-item"
        v-for="report in listOfReports"
        :key="report.slug"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectReport(report.slug)"
        >
          <span class="label">{{
            displayLabelName("reports.reports." + report.slug)
          }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                v-if="report.id === selectedReport"
                icon="#cx-app1-checkmark"
              />
              <icon
                v-else-if="activeReports.includes(report.slug)"
                icon="#cx-app1-arrow-right-12x12"
                height="12"
                width="12"
              />
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "Connectors",
  data() {
    return {
      selectedReport: null,
      processing: false,
      searchQuery: "",
      subdomain: null,
      reports: [
        { slug: "reservations", name: "Reservations and bookings" },
        { slug: "resources", name: "Resources" },
        { slug: "levels", name: "Levels" },
        { slug: "users", name: "Users" },
        { slug: "services", name: "Services" },
        { slug: "occupancy", name: "Occupancy" },
        { slug: "count", name: "Count" }
      ],
      activeReports: ["services", "reservations", "occupancy", "count"]
    };
  },
  mounted() {
    this.getConnectors(8);
  },
  computed: {
    ...mapState("connector", ["connectors"]),
    listOfReports() {
      const query = this.searchQuery;
      if (this.reports && this.reports.length) {
        if (query && query.length > 1) {
          return this.reports.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.reports;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("connector", [
      "setSelectedConnector",
      "setSelectedConnectorType",
      "getConnectors"
    ]),
    selectReport(slug) {
      this.selectedReport = slug;
      if (this.activeReports.includes(slug)) {
        if (slug == "occupancy") {
          this.$router.push({
            name: "r_occupancy-dashboard"
          });
        } else if (slug == "count") {
          this.$router.push({
            name: "r_count-dashboard"
          });
        } else {
          this.$router.push({
            name: "r_report",
            params: { report: slug }
          });
        }
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
